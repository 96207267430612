import _ from 'lodash';
import { memo } from 'react';
import { NextPage } from 'next';

import { PSection } from 'src/interface/section';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import WidgetTags from 'src/components/widget/widget-tags';
import styled from '@emotion/styled';
import { SHOW_COMMEMORATE } from 'src/constants';

type Props = {
  className?: string;
  color?: string;
  tags?: PSection['tags'];
  title?: string;
  tagsIndex?: string;
  inPage?: string;
  eventDataLayer?: IDataLayerGlobal;
  router?: any;
};
const SectionTags: NextPage<Props> = ({ className = '', color = '', tags, title, tagsIndex, inPage = 'default', eventDataLayer }) => {
  // console.log('SectionTags', tags);
  if (_.isEmpty(tags?.data)) return null;
  return (
    <SectionWrapper id='tags' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }} >
      {/* ---------------------------------- TAGS ---------------------------------- */}
      {!_.isEmpty(tags?.data) && (
        <WidgetTags
          title={`${title ? title : 'แท็กยอดนิยม'}`}
          className={className}
          // desc='อัพเดตกันก่อนใคร กับแท็กยอดนิยท ที่รวมคำค้นหา วลีเด็ด ยอดนิยมประจำวัน'
          data={tags?.data}
          tagsIndex={`${tagsIndex}`}
          color={color || '#000'}
          inPage={inPage}
          // ! DATALAYER
          eventDataLayer={{
            ...eventDataLayer
          }}
        />
      )}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  display: block;
  padding: 30px 0;
  .bg-hastag {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
export default memo(SectionTags);
