import { IDataLayerGlobal } from 'src/interface/data-layer';
import { Media } from 'src/interface/media';
// import _ from 'lodash';
import useResponsive from 'src/utils/devices';
import { ITags } from 'src/interface/service';
import SectionTags from 'src/components/sections/section-tags';
import styled from '@emotion/styled';

type Props = {
  eventDataLayer: IDataLayerGlobal;
  dataTags?: { data: ITags[] };
};

const SectionTag = ({ dataTags, eventDataLayer }: Props) => {
  const responsive: Media = useResponsive();

  return (
    <>
      <SectionTagWrapper>
        <div className='container'>
          <SectionTags
            tags={dataTags}
            title='แท็กยอดนิยม'
            color='#194588'
            tagsIndex='index'
            // ! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              section: 'แท็กยอดนิยม',
              position: 'ใต้:section-2',
              data: {
                heading: 'แท็กยอดนิยม',
                ...eventDataLayer?.data
              }
            }}
          />
        </div>
      </SectionTagWrapper>
      {responsive.isTabletMini && (
        <MobileSectionTagWrapper>
          <div className='container'>
            <SectionTags
              tags={dataTags}
              title='แท็กยอดนิยม'
              color='#194588'
              tagsIndex='index'
              // ! DATALAYER
              eventDataLayer={{
                ...eventDataLayer,
                section: 'แท็กยอดนิยม',
                position: 'ใต้:section-2',
                data: {
                  heading: 'แท็กยอดนิยม',
                  ...eventDataLayer?.data
                }
              }}
            />
          </div>
        </MobileSectionTagWrapper>
      )}
    </>
  );
};

export default SectionTag;

const SectionTagWrapper = styled.div`
  background: #f4f4f4;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileSectionTagWrapper = styled.div`
  background: #f4f4f4;
  width: 100%;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;